<template>
  <div>
    <VoucherConfigureButton
      :icon="SVG_ICON.SQUARE_EDIT_OUTLINE"
      :label="t('btn.personalization')"
      :description="t('btn.personalizationText')"
      @click="isModalOpen = true"
    />

    <LazyVoucherConfigurePersonalizationModal
      v-if="isModalOpen"
      :index
      @close="isModalOpen = false"
    />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  index: number
}

const { index } = defineProps<Props>()

const { t } = useI18n()

const isModalOpen = ref(false)

defineOptions({
  name: 'VoucherConfigurePersonalization',
})
</script>

<i18n>
de:
  optional: "(optional)"
  btn:
    personalization: "Personalisierung"
    personalizationText: "Persönliche Nachricht hinzufügen"
</i18n>
