<template>
  <div>
    <div class="mb-4 flex items-center space-x-2">
      <Button
        v-if="voucherProducts?.length > 1"
        :theme="BUTTON_THEME.TERTIARY"
        :title="t('btn.back')"
        :icon="SVG_ICON.CHEVRON_LEFT"
        @click="setStep(VOUCHER_SHOP_STEP.SELECT_VOUCHER)"
      />
      <h1 class="text-xl font-bold" v-text="voucherProduct!.name" />
    </div>

    <div
      class="grid grid-cols-1 place-content-start gap-6 md:grid-cols-[60%,minmax(0,1fr)] md:grid-rows-[auto,1fr] md:gap-x-10"
    >
      <div class="space-y-4 md:order-last md:row-span-2">
        <VoucherProductImage
          :voucher-product="voucherProduct!"
          :render-above-the-fold="true"
          class="hidden md:block"
        />
        <h3 class="text-lg font-bold" v-text="t('terms')" />
        <div
          class="prose dark:prose-invert prose-li:list-disc dark:prose-li:marker:text-light prose-li:marker:text-dark prose-li:my-1 prose-li:leading-tight"
          v-html="voucherProduct!.terms"
        />
      </div>
      <div>
        <FormKit
          v-model="formData"
          type="form"
          :actions="false"
          @submit="addToCart"
        >
          <FormKit
            type="hidden"
            name="cartVoucherId"
            :value="voucherProduct!.cartVoucherId"
          />

          <div class="space-y-6">
            <FormKit
              type="dropdown"
              :label="t('label.giftCardValue')"
              name="selectedAmount"
              :options="amountOptions"
              popover="true"
              validation="required"
            />

            <FormKit
              type="hidden"
              name="cartVoucherId"
              :value="voucherProduct!.cartVoucherId"
            />

            <FormKit
              :label="t('label.quantity')"
              name="quantity"
              type="stepNumber"
              :min="0"
              :max="10"
              :value="formData.quantity"
            />

            <FormKit
              v-if="voucherProduct!.voucherDesigns.length > 1"
              :label="t('label.design')"
              name="voucherDesignId"
              required
              validation="required"
              type="radio"
              :options="designOptions"
              :classes="{
                options: '$reset grid grid-cols-2 gap-3 mt-2',
                option: '$reset h-full',
                wrapper: '$reset input-radio-button-select-button',
                inner: '$reset hidden',
              }"
            >
              <template #label="{ option }">
                <VoucherProductDesignOption :option />
              </template>
            </FormKit>

            <FormKit
              v-else
              type="hidden"
              name="voucherDesignId"
              :value="voucherProduct!.voucherDesigns[0].id"
            />

            <div v-for="i in formData.quantity" :key="i" class="space-y-4">
              <Divider
                v-if="formData.quantity > 1"
                :label="t('dividerLabel', { index: i })"
              />
              <VoucherConfigurePersonalization :index="i - 1" :cinema />
            </div>

            <div class="grid grid-cols-1 gap-2 md:grid-cols-2">
              <VoucherConfigurePreview
                :voucher-product-id="voucherProduct!.id"
                :voucher-design-id="formData.voucherDesignId!"
                :customisations="voucherProduct!.customisations"
                :selected-amount="formData.selectedAmount!"
                :quantity="formData.quantity"
                :cinema
              />

              <Button
                :text="
                  formData.cartVoucherId ? t('btn.update') : t('btn.addToCart')
                "
                :disabled="
                  !formData.selectedAmount || !formData.voucherDesignId
                "
                :type="BUTTON_TYPE.SUBMIT"
              />
            </div>
          </div>
        </FormKit>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema?: Cinema
}

const { t, n, locale } = useI18n()
const { cinema } = defineProps<Props>()
const { patchVoucherProducts } = useVoucherCart({ cinema })
const { voucherProduct, voucherProducts, setStep } = await useVoucherShop({
  cinema,
})

const designOptions = computed(() =>
  voucherProduct.value?.voucherDesigns.map(({ id, name, thumbnail }) => ({
    label: name,
    value: id,
    thumbnail,
  }))
)

const amountOptions = computed(() =>
  voucherProduct.value?.priceOptions.map((priceOption) => ({
    label: n(priceOption?.price, 'currency'),
    value: priceOption?.price,
  }))
)

const formData = ref({
  quantity: voucherProduct.value!.quantity ?? 1,
  selectedAmount: voucherProduct.value!.selectedAmount,
  voucherDesignId:
    voucherProduct.value!.voucherDesignId ??
    voucherProduct.value!.voucherDesigns[0].id,
  cartVoucherId: voucherProduct.value!.cartVoucherId,
})

const { add: addMessage } = useMessage()

async function addToCart({
  quantity,
  selectedAmount,
  voucherDesignId,
  cartVoucherId,
}: {
  quantity: number
  selectedAmount: number
  voucherDesignId: string
  cartVoucherId: string
}) {
  const { id: voucherProductId, customisations } = voucherProduct.value!

  try {
    await patchVoucherProducts([
      {
        id: cartVoucherId ?? undefined,
        voucherProductId,
        voucherDesignId,
        customisations,
        locale: locale.value,
        quantity,
        selectedAmount,
      },
    ])

    addMessage({
      message: !quantity
        ? t('toast.voucherDeletedFromCart')
        : cartVoucherId
          ? t('toast.voucherUpdatedInCart')
          : t('toast.voucherAddedToCart'),
      severity: MESSAGE_SEVERITY.SUCCESS,
    })

    setStep(VOUCHER_SHOP_STEP.CART)
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : t('toast.genericError')

    addMessage({
      message: errorMessage,
      severity: MESSAGE_SEVERITY.ERROR,
    })
  }
}

defineOptions({
  name: 'VoucherProductConfigure',
})
</script>

<i18n>
de:
  terms: "Konditionen"
  optional: "(optional)"
  dividerLabel: "Gutschein {index}"
  label:
    giftCardValue: "Gutscheinwert"
    design: "Design"
    quantity: "Anzahl"
  btn:
    back: "Zurück"
    addToCart: "Zum Warenkorb hinzufügen"
    update: "Aktualisieren"
  toast:
    voucherAddedToCart: "Gutschein erfolgreich zum Warenkorb hinzugefügt"
    voucherUpdatedInCart: "Gutschein erfolgreich im Warenkorb aktualisiert"
    voucherDeletedFromCart: "Gutschein erfolgreich aus dem Warenkorb entfernt"
    genericError: "Es ist ein Fehler aufgetreten"
es:
  terms: "Términos"
  optional: "(opcional)"
  dividerLabel: "Tarjeta de regalo {index}"
  label:
    giftCardValue: "Valor de la tarjeta de regalo"
    design: "Diseño"
    quantity: "Cantidad"
  btn:
    back: "Volver"
    addToCart: "Añadir al carrito"
    update: "Actualizar"
  toast:
    voucherAddedToCart: "Tarjeta de regalo añadida correctamente al carrito"
    voucherUpdatedInCart: "Tarjeta de regalo actualizada correctamente en el carrito"
    voucherDeletedFromCart: "Tarjeta de regalo eliminada correctamente del carrito"
    genericError: "Se ha producido un error"
</i18n>
