<template>
  <div
    class="dark:bg-neutral-dark-2 bg-neutral-light-1 text:dark rounded-md px-4 py-2"
  >
    <div class="dark:divide-neutral-dark-1 divide-neutral-light-1 divide-y">
      <div class="flex justify-between py-2">
        <div v-text="t('subTotalPrice')" />
        <div v-text="n(price.total, 'currency')" />
      </div>

      <div class="flex flex-wrap justify-between py-2">
        <div class="text-lg font-bold" v-text="t('totalPrice')" />
        <div class="text-lg font-bold" v-text="n(price.total, 'currency')" />
        <small class="mt-1 w-full" v-text="t('taxInfo')" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Price } from '#gql/default'

interface Props {
  price: Price
}

const { t, n } = useI18n()
const { price } = defineProps<Props>()

defineOptions({
  name: 'CartPriceInfo',
})
</script>

<i18n>
de:
  subTotalPrice: "Zwischensumme"
  totalPrice: "Gesamt"
  taxInfo: "inkl. aller Gebühren & Steuern"
</i18n>
