import { useRouteQuery } from '@vueuse/router'
import type {
  VoucherProductCustomisation,
  VoucherShopStep,
} from '@base/types/voucher-shop'
import type { Cinema, VoucherProduct, CartVoucherProduct } from '#gql/default'

type CustomisedVoucherProduct = VoucherProduct & {
  quantity: number
  cartVoucherId?: string
  selectedAmount?: number
  voucherDesignId?: string
  customisations: VoucherProductCustomisation[]
}

export default async function useVoucherShop({ cinema }: { cinema?: Cinema }) {
  const voucherProducts = useState<VoucherProduct[]>(
    'voucherProducts',
    () => []
  )
  const voucherProduct = useState<CustomisedVoucherProduct | null>(
    'voucherProduct',
    () => null
  )
  const step = useRouteQuery<VoucherShopStep>('step')
  const voucherId = useRouteQuery<string | undefined>('vi')
  const cartVoucherId = useRouteQuery<string | undefined>('cvi')

  const { data, execute } = await useAsyncGql(
    'FetchVoucherProducts',
    { cinemaId: cinema?.id },
    { immediate: false }
  )

  if (!voucherProducts.value.length) {
    await execute()
    voucherProducts.value = data.value?.voucherProducts as VoucherProduct[]
    await setInitialState()
  }

  function getVoucherProduct(id: string) {
    return voucherProducts.value.find(
      (voucherProduct) => id === voucherProduct.id
    )
  }

  /**
   * Configure the voucher product
   * @param selectedVoucherProduct - The voucher product to configure
   */
  function configure({
    voucher,
    cartVoucher,
  }: {
    voucher?: VoucherProduct
    cartVoucher?: CartVoucherProduct
  }) {
    const selectedVoucher =
      voucher ?? (cartVoucher?.voucherProduct as VoucherProduct)

    voucherProduct.value = {
      ...selectedVoucher,
      quantity: cartVoucher?.quantity ?? 1,
      selectedAmount: cartVoucher?.selectedAmount,
      voucherDesignId: cartVoucher?.voucherDesign.id,
      cartVoucherId: cartVoucher?.id,
      customisations:
        (cartVoucher?.customisations as VoucherProductCustomisation[]) ?? [],
    }

    voucherId.value = selectedVoucher.id
    cartVoucherId.value = cartVoucher?.id
    setStep(VOUCHER_SHOP_STEP.CONFIGURE_VOUCHER)
  }

  /**
   * Set the initial state of the voucher shop
   */
  function setInitialState() {
    // Due to the limitations of useRouteQuery, we need to
    // check for the route query cartId to determine if the
    // cart is empty or not. The rest is handled in useCart().
    const route = useRoute()

    if (
      (step.value === VOUCHER_SHOP_STEP.CHECKOUT ||
        step.value === VOUCHER_SHOP_STEP.CART) &&
      !route.query.cartId
    ) {
      return voucherProducts.value.length === 1
        ? configure({ voucher: voucherProducts.value[0] })
        : setStep(VOUCHER_SHOP_STEP.SELECT_VOUCHER)
    }

    if (step.value === VOUCHER_SHOP_STEP.CONFIGURE_VOUCHER && voucherId.value) {
      const selectedProduct = getVoucherProduct(voucherId.value)
      if (selectedProduct) {
        return configure({ voucher: selectedProduct })
      }
    }

    if (voucherProducts.value.length === 1) {
      return configure({ voucher: voucherProducts.value[0] })
    }

    if (step.value) {
      setStep(step.value as VoucherShopStep)
    } else {
      setStep(VOUCHER_SHOP_STEP.SELECT_VOUCHER)
    }
  }

  /**
   * Customize the voucher product
   * @param index - The index of the customisation to customize
   * @param data - The data to customize
   */
  function customize(index: number, data: VoucherProductCustomisation) {
    if (!voucherProduct.value) {
      return
    }
    while (voucherProduct.value.customisations.length <= index) {
      voucherProduct.value.customisations.push({})
    }
    voucherProduct.value.customisations[index] = {
      ...voucherProduct.value.customisations[index],
      ...data,
    }
  }

  function setStep(nextStep: VoucherShopStep) {
    if (nextStep !== VOUCHER_SHOP_STEP.CONFIGURE_VOUCHER) {
      voucherId.value = undefined
      cartVoucherId.value = undefined
    }

    step.value = nextStep
  }

  return {
    step,
    voucherProducts,
    voucherProduct,
    configure,
    customize,
    setStep,
  }
}
