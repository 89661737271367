<template>
  <Cart>
    <template #default>
      <div
        class="dark:bg-neutral-dark-2 bg-neutral-light-1 text:dark rounded-md px-4 py-2"
      >
        <div class="dark:divide-neutral-dark-1 divide-neutral-light-1 divide-y">
          <VoucherCartProduct
            v-for="cartVoucherProduct in cart?.voucherProducts"
            :key="cartVoucherProduct.id"
            :cart-voucher-product
          />
        </div>
      </div>

      <Button
        :text="t('btn.addMore')"
        class="w-full"
        @click="setStep(VOUCHER_SHOP_STEP.SELECT_VOUCHER)"
      />
    </template>
    <template #checkout>
      <Button
        :text="t('btn.checkout')"
        class="w-full"
        @click="setStep(VOUCHER_SHOP_STEP.CHECKOUT)"
      />
    </template>
  </Cart>
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema?: Cinema
}

const { cinema } = defineProps<Props>()
const { cart } = await useVoucherCart({ cinema })
const { setStep } = await useVoucherShop({ cinema })

const { t } = useI18n()

defineOptions({
  name: 'VoucherCart',
})
</script>

<i18n>
de:
  btn:
    checkout: "Zur Kasse"
    addMore: "Weitere Produkte hinzufügen"
es:
  btn:
    checkout: "Zur Kasse"
    addMore: "Weitere Produkte hinzufügen"
</i18n>
