<template>
  <div class="w-full">
    <p class="mb-4" v-text="t('payEasyAndSecure')" />

    <template v-for="{ name: key } in availablePaymentMethods">
      <InlineSvg
        v-if="paymentMethodsIcons[key]"
        :key
        :type="INLINE_SVG_TYPE.PAYMENT_ICON"
        :name="paymentMethodsIcons[key].name"
        class="mb-2 mr-4 inline-flex w-auto shrink-0"
        :class="paymentMethodsIcons[key].classes"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
interface Props {
  availablePaymentMethods: any[]
}

const { availablePaymentMethods } = defineProps<Props>()
const { design } = useDesign()
const { t } = useI18n()

const {
  PAYPAL_DARK,
  PAYPAL_LIGHT,
  AMAZON_DARK,
  AMAZON_LIGHT,
  GPAY_DARK,
  GPAY_LIGHT,
  CREDITCARD,
  LOYALTY,
  UNZER,
  KLARNA,
} = PAYMENT_ICONS

const paymentMethodsIcons = {
  Paypal: {
    get name() {
      return design.value?.isDarkTheme ? PAYPAL_DARK : PAYPAL_LIGHT
    },
    classes: 'h-6',
  },
  Amazon: {
    get name() {
      return design.value?.isDarkTheme ? AMAZON_DARK : AMAZON_LIGHT
    },
    classes: 'h-5',
  },
  GooglePay: {
    get name() {
      return design.value?.isDarkTheme ? GPAY_LIGHT : GPAY_DARK
    },
    classes: 'h-6',
  },
  Kps: {
    name: CREDITCARD,
    classes: 'h-6',
  },
  Sofort: {
    name: UNZER,
    classes: 'h-4',
  },
  Klarna: {
    name: KLARNA,
    classes: 'h-8',
  },
  Loyalty: {
    name: LOYALTY,
    classes: 'h-8',
  },
}

defineOptions({
  name: 'CartAvailablePaymentMethods',
})
</script>

<i18n>
de:
  payEasyAndSecure: "Einfach und sicher bezahlen, mit"
es:
  payEasyAndSecure: "Paga fácil y seguro, con"
</i18n>
