<template>
  <div v-if="voucherProducts.length">
    <VoucherProductConfigure
      v-if="step === CONFIGURE_VOUCHER && voucherProduct"
      :cinema
    />
    <VoucherProductsList
      v-else-if="step === SELECT_VOUCHER"
      :cinema
      :voucher-products
    />
    <VoucherCart v-else-if="step === CART && cart" :cinema />
    <VoucherCheckout v-else-if="step === CHECKOUT && cart" :cinema />
  </div>
</template>

<script setup lang="ts">
import { VOUCHER_SHOP_STEP } from '@base/constants/voucher-shop'
import type { Cinema } from '#gql/default'

interface Props {
  cinema?: Cinema
}

const { cinema } = defineProps<Props>()
const { cart } = await useVoucherCart({ cinema })
const { step, voucherProducts, voucherProduct } = await useVoucherShop({
  cinema,
})

const { SELECT_VOUCHER, CONFIGURE_VOUCHER, CART, CHECKOUT } = VOUCHER_SHOP_STEP

defineOptions({
  name: 'VoucherShop',
})
</script>

<i18n>
de:
  btn:
    checkout: "Zur Kasse"
es:
  btn:
    checkout: "Checkout"
</i18n>
