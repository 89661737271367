import type {
  CartVoucherProductInput,
  Cart,
  CartType,
  Cinema,
} from '#gql/default'

export default function useVoucherCart({ cinema }: { cinema?: Cinema }) {
  const { cart, ensureCart } = useCart()

  async function patchVoucherProducts(
    voucherProducts: CartVoucherProductInput[]
  ): Promise<Cart> {
    try {
      const { id } = await ensureCart({ cinema, type: 'VOUCHER' as CartType })

      const result = await GqlCartPatchVoucherProducts({
        cartId: id,
        voucherProducts,
      })

      if (!result?.cartPatchVoucherProducts) {
        throw new Error('Failed to patch voucher products')
      }

      // Update the cart with the new data
      const updatedCart = result.cartPatchVoucherProducts as Cart
      cart.value = updatedCart

      return updatedCart
    } catch (error) {
      console.error('Error patching voucher products:', error)
      throw error // Re-throw the error for the component to handle
    }
  }

  return {
    cart,
    patchVoucherProducts,
  }
}
