import { useRouteQuery } from '@vueuse/router'
import type { GqlError } from 'nuxt-graphql-client'
import type { Cart, CartType, Cinema } from '#gql/default'

export default function useCart() {
  const { add: addMessage } = useMessage()
  const cart = useState<Cart | null>('cart', () => null)
  const cartId = useRouteQuery('cartId', undefined as string | undefined)
  const isCartExpired = ref(false)

  const expiresAt = computed(() => cart.value?.expiresAt ?? null)
  const availablePaymentMethods = computed(
    () => cart.value?.availablePaymentMethods ?? []
  )

  const {
    start: startCountdown,
    stop: stopCountdown,
    isFinished,
  } = useCountdown(() => cart.value?.expiresAt || '')

  watch(
    () => cart.value?.expiresAt,
    (newExpiresAt) => {
      stopCountdown()
      if (newExpiresAt) {
        startCountdown()
      }
    }
  )

  watch(isFinished, (newValue) => {
    isCartExpired.value = newValue
    if (newValue) {
      handleCartExpiration()
    }
  })

  function handleCartExpiration() {
    cart.value = null
    // Additional expiration handling (e.g., showing a message)
  }

  async function getCart(id: string) {
    const { data } = await useAsyncGql('FetchCart', {
      cartId: id,
    })

    // if cart is not found, remove cartId from url
    // maybe clean more things here and we use useRedirectWithError here
    if (!data.value?.cart) {
      cartId.value = undefined as unknown as string
    }

    cart.value = data.value?.cart as unknown as Cart
  }

  async function ensureCart({
    cinema,
    type,
    addToCartIdToUrl = true,
  }: {
    cinema?: Cinema
    type: CartType
    addToCartIdToUrl?: boolean
  }): Promise<Cart> {
    if (!cart.value || !cartId.value) {
      const result = await GqlCartCreate({
        cinemaId: cinema?.id,
        type,
      })

      if (!result?.cartCreate) {
        throw new Error('Failed to create cart')
      }

      // add cartId to url
      if (addToCartIdToUrl) {
        cartId.value = result.cartCreate.id
      }

      cart.value = result.cartCreate as Cart
    }

    return cart.value
  }

  onMounted(async () => {
    // If cartId is in url, try to restore cart
    if (cartId.value) {
      await getCart(cartId.value)
    }
  })

  return {
    cart,
    ensureCart,
    expiresAt,
    availablePaymentMethods,
  }
}
