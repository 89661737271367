<template>
  <PageGrid
    v-if="cart"
    :gap="{ default: PAGE_GRID_GAP_SIZE.MD }"
    :layout="{ sm: PAGE_GRID_LAYOUT.COLS_2_1 }"
  >
    <PageGridColumn>
      <PageHeading :tag="PAGE_HEADING_TAG.H2" :title="t('cart')" />
      <slot />
    </PageGridColumn>

    <PageGridColumn>
      <CartCountdown v-if="cart.expiresAt" :expires-at />
      <CartPriceInfo :price="cart.price" />
      <CartAvailablePaymentMethods :available-payment-methods />

      <slot name="checkout" />
    </PageGridColumn>
  </PageGrid>
</template>

<script setup lang="ts">
const { cart, expiresAt, availablePaymentMethods } = useCart()

const { t } = useI18n()

defineOptions({
  name: 'Cart',
})
</script>

<i18n>
de:
  cart: "Warenkorb"
es:
  cart: "Carrito"
</i18n>
