<template>
  <div
    class="bg-system-success/80 border-system-success text-on-system-success flex select-none items-center gap-2 rounded-md border p-2"
  >
    <InlineSvg :name="SVG_ICON.CALENDAR" class="size-6 fill-current" />
    <span v-text="t('reservationTime')" />
    <span class="tabular-nums" v-text="formattedTime" />
  </div>
</template>

<script setup lang="ts">
interface Props {
  expiresAt: string
  showDays?: boolean
}

const { expiresAt } = defineProps<Props>()
const { t } = useI18n()
const { timeUnits, start, cleanup } = useCountdown(expiresAt)

const formattedTime = computed(() => {
  const { hours, minutes, seconds } = timeUnits.value
  const parts = [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ].filter(Boolean)

  return parts.join(':')
})

onMounted(start)
onUnmounted(cleanup)

defineOptions({ name: 'Countdown' })
</script>

<i18n>
de:
  reservationTime: "Reservierungszeit:"
es:
  reservationTime: "Tiempo de reserva:"
</i18n>
