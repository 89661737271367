<template>
  <div class="grid grid-cols-[auto,minmax(0,1fr),auto] gap-x-6 py-4">
    <div
      class="col-span-2"
      v-text="
        t('quantityAndName', {
          quantity: cartVoucherProduct.quantity,
          name: cartVoucherProduct.voucherProduct.name,
        })
      "
    />
    <div
      v-text="
        n(
          cartVoucherProduct.quantity * cartVoucherProduct.selectedAmount,
          'currency'
        )
      "
    />
    <div class="col-span-3">
      <div v-for="({ text }, i) in cartVoucherProduct.customisations" :key="i">
        {{ text }}
      </div>
    </div>

    <Button
      :text="t('btn.edit')"
      :theme="BUTTON_THEME.SECONDARY"
      class="inline-flex"
      @click="configure({ cartVoucher: cartVoucherProduct })"
    />

    <div class="col-start-3">
      <FormKit
        type="stepNumber"
        name="quantity"
        :min="0"
        :max="10"
        :value="cartVoucherProduct.quantity"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CartVoucherProduct, Cinema } from '#gql/default'

interface Props {
  cartVoucherProduct: CartVoucherProduct
  cinema?: Cinema
}

const { cartVoucherProduct, cinema } = defineProps<Props>()
const { configure } = await useVoucherShop({ cinema })
const { t, n } = useI18n()

defineOptions({
  name: 'VoucherCartProduct',
})
</script>

<i18n>
de:
  quantityAndName: "{quantity} × {name}"
  btn:
    edit: "Bearbeiten"
es:
  quantityAndName: "{quantity} × {name}"
  btn:
    edit: "Editar"
</i18n>
