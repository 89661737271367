import { ref, computed } from 'vue'

interface TimeUnits {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export function useCountdown(expiresAt: string | (() => string)) {
  const timeUnits = ref<TimeUnits>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const isFinished = ref(false)
  let intervalId: number | null = null

  const getExpiresAt = computed(() => {
    return typeof expiresAt === 'function' ? expiresAt() : expiresAt
  })

  const updateCountdown = () => {
    const timeLeft =
      new Date(getExpiresAt.value).getTime() - new Date().getTime()

    if (timeLeft <= 0) {
      timeUnits.value = { days: 0, hours: 0, minutes: 0, seconds: 0 }
      isFinished.value = true
      stop()
      return
    }

    timeUnits.value = {
      days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
      hours: Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
    }
  }

  const start = () => {
    updateCountdown()
    intervalId = window.setInterval(updateCountdown, 1000)
  }

  const stop = () => {
    if (intervalId !== null) {
      clearInterval(intervalId)
      intervalId = null
    }
  }

  const cleanup = () => {
    stop()
    timeUnits.value = { days: 0, hours: 0, minutes: 0, seconds: 0 }
    isFinished.value = false
  }

  const formattedTime = computed(() => {
    const { days, hours, minutes, seconds } = timeUnits.value
    return [days, hours, minutes, seconds]
      .map((unit) => unit.toString().padStart(2, '0'))
      .join(':')
  })

  return { timeUnits, formattedTime, isFinished, start, stop, cleanup }
}
