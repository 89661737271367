<template>
  <div>
    <Button
      :text="t('btn.preview')"
      :theme="BUTTON_THEME.SECONDARY"
      class="w-full"
      :disabled="!selectedAmount || !voucherDesignId"
      @click="isModalOpen = true"
    />

    <LazyVoucherConfigurePreviewModal
      v-if="isModalOpen && selectedAmount && voucherDesignId"
      :cinema
      :voucher-product-id
      :customisations
      :selected-amount
      :voucher-design-id
      :quantity
      @close="isModalOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import type { VoucherProductCustomisation } from '@base/types/voucher-shop'
import type { Cinema } from '#gql/default'

interface Props {
  cinema?: Cinema
  voucherProductId: string
  customisations: VoucherProductCustomisation[]
  selectedAmount?: number
  voucherDesignId?: string
  quantity: number
}

const { cinema } = defineProps<Props>()
const isModalOpen = ref(false)

const { t } = useI18n()

defineOptions({
  name: 'VoucherConfigurePreview',
})
</script>

<i18n>
de:
  btn:
    preview: "PDF Vorschau anzeigen"
es:
  btn:
    preview: "Ver PDF de vista previa"
</i18n>
